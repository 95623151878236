import React from "react";
import "./Join.css";
import { useRef } from "react";
import emailjs from '@emailjs/browser'

export default function Join() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_azgr2ex', 'template_ivn96eu', form.current, 'O_sxK4RFBRnmejTC1').then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        })
    }
  return (
    <div className="Join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">Ready To </span>
          <span>Level Up </span>
        </div>
        <div>
          <span>Your Body </span>
          <span className="stroke-text">With Us?</span>
        </div>
      </div>
      <div className="right-j">
        <form ref = {form} action="" className="email-container" onSubmit={sendEmail}>
            <input type="email" name = "user_email" placeholder="enter your email address"/>
            <button className="btn btn-j">Join Now</button>
        </form>
      </div>
    </div>
  );
}
