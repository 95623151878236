import React from 'react'
import './Hero.css'
import Header from "../Header/Header"
import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets/hero_image_back.png"
import heart from "../../assets/heart.png"
import calories from "../../assets/calories.png"
import NumberCounter from "number-counter"
import { useAnimation, motion } from "framer-motion";

export default function Hero() {
    const transition = {type:'spring', duration:3};
    const mobile = window.innerWidth<=768 ? true : false;
  return (
    <div className = "hero" id='home'>
        <div className="blur hero-blur"></div>
        <div className="left-h">
        <Header/>
        <div className="the-best-ad">
            <motion.div
            initial = {{left: mobile?"165px":"239px"}}
            whileInView = {{left: "8px"}}
            transition = {{...transition, type: "linear"}}>

            </motion.div>
            <span>The Best Fitness Club in the TOWN</span>
        </div>

        <div className="hero-text">
            <div>
                <span className='stroke-text'>Shape </span>
                <span>Your</span>
            </div>
            <div>
                <span>Ideal Body</span>
            </div>
            <div>
                <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, eaque? Assumenda incidunt quo deserunt officiis similique, dolorum molestiae numquam corporis quam placeat eos, amet itaque provident soluta eius aspernatur ipsam.</span>
            </div>
        </div>
        <div className="figures">
            <div>
                <NumberCounter end = {140} start = {100} delay = '4' preFix="+"/>
                <span>expert coaches</span>
            </div>
            <div>
            <NumberCounter end = {978} start = {500} delay = '4' preFix="+"/>
                <span>Members Joined</span>
            </div>
            <div>
            <NumberCounter end = {50} start = {0} delay = '4' preFix="+"/>
                <span>Fitness Programs</span>
            </div>
        </div>

        <div className="hero-buttons">
            <buttons className="btn">Get Started</buttons>
            <buttons className="btn">Learn More</buttons>
        </div>
      </div>
      <div className="right-h">
        <button className="btn">Join Now</button>
        <motion.div 
            initial = {{right: "-1rem"}}
            whileInView = {{right: "4rem"}}
            transition = {transition}
            className="heart-rate">
            <img src = {heart} alt = "heart" />
            <span>Heart Rate</span>
            <span>116 bpm</span>
        </motion.div>
        <img src = {hero_image} alt = "hero_image" className='hero_image'/>
        <motion.img 
        initial = {{right: "11rem"}}
        whileInView = {{right: "20rem"}}
        transition = {transition}
        src = {hero_image_back} alt = "hero_image_back" className='hero_image_back'/>

        <motion.div 
        initial = {{right: "37rem"}}
        whileInView = {{right: "24rem"}}
        transition = {transition}
        className='calories'>
            <img src = {calories} alt = "calories"/>
            <div>
                <span>Calories Burned</span>
                <span>220 Kcal</span>
            </div>
        </motion.div>
      </div>
    </div>
  )
}
